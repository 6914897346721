import axios from "axios";
import Cookies from "universal-cookie";
import { BASE_URL } from "utils/Base";
import Router from "next/router";
import { api } from "./base";

const cookies = new Cookies();

const merchantToken = cookies.get("merchantToken");
const shopId = cookies.get("shopId");
// switch language
export const switchMessageLanguage = ({ messageLanguage }) =>
  api.patch(`/chatlanguageConfig`, {
    shopId: shopId,
    merchantToken: merchantToken,
    msgLanguage: messageLanguage,
  });

export const getBasicDetails = (
  merchantToken = merchantToken,
  shopId = shopId
) =>
  api.post(`/getChatSettings`, {
    merchantToken: merchantToken,
    shopId: shopId,
  });

export const updateBasicDetails = ({
  storeName,
  storeUrl,
  storeEmail,
  contactNumber,
  countryCode,
}) =>
  api.patch(`/updateStoreBasics`, {
    merchantToken: merchantToken,
    shopId: shopId,
    storeName,
    storeUrl,
    storeEmail,
    contactNumber,
    countryCode,
  });

export const updateScheduleTiming = ({
  timeGap,
  messageEvent,
  fallBackTiming,
}) =>
  api.patch(`/updateOrderSettings`, {
    merchantToken: merchantToken,
    shopId: shopId,
    timeGap,
    messageEvent,
    fallBackTiming,
  });

export const updateLineItem = ({ itemLimit }) =>
  api.patch(`/updateOrderSettings`, {
    merchantToken: merchantToken,
    shopId: shopId,
    itemLimit,
  });

export const getChatFlow = () =>
  api.post(`/messageTemplates`, {
    merchantToken: merchantToken,
    shopId: shopId,
  });

export const getDiscountVariables = () =>
  api.post(`/chatFlow/discountVariablelist`, {
    merchantToken: merchantToken,
    shopId: shopId,
  });

export const getTemplates = ({
  templateTitle,
  languageTitle = "English",
  approveStatus = "All",
}) =>
  api.post(`/messageTemplateList`, {
    merchantToken: merchantToken,
    shopId: shopId,
    templateTitle,
    languageTitle,
    approveStatus,
  });

export const updateModerationSettings = ({
  minimumRating,
  autoApprovalStatus,
  autoMediaApproval,
  autoPublishStatus,
}) =>
  api.patch(`/updateModerationSettings`, {
    merchantToken: merchantToken,
    shopId: shopId,
    minimumRating: minimumRating,
    autoApprovalStatus: autoApprovalStatus,
    autoMediaApproval: autoMediaApproval,
    autoPublishStatus: autoPublishStatus,
  });

export const customerSupportUpdate = ({
  supportNumber,
  countryCode,
  contactNumber,
  supportEmail,
}) =>
  api.patch(`/customerSupportUpdate`, {
    merchantToken: merchantToken,
    shopId: shopId,
    supportNumber,
    contactNumber,
    supportEmail,
    countryCode,
  });

export const updateDiscountEvent = ({ event, template }) =>
  api.patch(`/discountMessageEvent`, {
    merchantToken: merchantToken,
    shopId: shopId,
    event,
    template,
  });

export const createDiscount = ({ discountType, discountValue, couponCode }) =>
  api.post(
    `/createDiscountCoupon?merchantToken=${merchantToken}&shopId=${shopId}`,
    {
      // merchantToken,
      // shopId,
      discountType,
      discountValue,
      couponCode,
    }
  );

// Chat flow
//
//
export const addNewTemplate = ({
  category,
  templateLabel,
  templateText,
  messageLanguage,
  buttons = null,
  discountMessage = false,
}) =>
  api.post(`/chatFlow/createTwilioTemplateMessages`, {
    merchantToken: merchantToken,
    shopId: shopId,
    templateLabel,
    category,
    templateText,
    messageLanguage,
    buttons,
    discountMessage,
  });

export const addFlowTemplate = ({ replySection, position, templateId, job }) =>
  api.patch(`/chatFlow/addNewTemplates`, {
    merchantToken: merchantToken,
    shopId: shopId,
    position,
    replySection,
    templateId,
    job,
  });

export const removeFlowTemplate = ({ replySection, position }) =>
  api.patch(`/chatFlow/removeTemplates`, {
    merchantToken: merchantToken,
    shopId: shopId,
    position,
    replySection,
  });

export const editFlowTemplate = ({
  replySection,
  templateId,
  templateText,
  templateLabel,
  discountMessage = false,
}) =>
  api.post(`/chatFlow/editMessage`, {
    merchantToken: merchantToken,
    shopId: shopId,
    templateId,
    replySection,
    templateText,
    templateLabel,
    discountMessage,
  });

export const removedFlowTemplates = ({ replySection }) =>
  api.post(`/removedTemplates`, {
    merchantToken: merchantToken,
    shopId: shopId,
    replySection,
  });

export const resetToDefault = ({ replySection }) =>
  api.patch(`/chatFlow/switchToDefault`, {
    merchantToken: merchantToken,
    shopId: shopId,
    replySection,
  });

export const deleteTemplate = ({ templateId }) =>
  api.patch(`chatFlow/deleteMessageTemplate`, {
    merchantToken: merchantToken,
    shopId: shopId,
    templateId,
  });

export const updateMessageStatus = ({
  templateId,
  replySection,
  followUpStatus,
}) =>
  api.patch(`chatFlow/followUpStatus`, {
    merchantToken: merchantToken,
    shopId: shopId,
    templateId,
    replySection,
    followUpStatus,
  });

export const publishDraft = () =>
  api.patch(`chatFlow/publishDraft`, {
    merchantToken: merchantToken,
    shopId: shopId,
  });

export const testDraft = ({ countryCode, phoneNumber }) =>
  api.post(`messagePreview`, {
    merchantToken: merchantToken,
    shopId: shopId,
    countryCode,
    phoneNumber,
  });

export const enableDiscount = ({ discountEnabled }) =>
  api.patch(`discountEnableOrDisable`, {
    merchantToken: merchantToken,
    shopId: shopId,
    discountEnabled,
  });

const settings_api = {
  switchMessageLanguage,
  getBasicDetails,
  updateBasicDetails,
  updateScheduleTiming,
  updateModerationSettings,
  customerSupportUpdate,
  createDiscount,
  updateLineItem,
  getChatFlow,
  getTemplates,
  removeFlowTemplate,
  removedFlowTemplates,
  addFlowTemplate,
  addNewTemplate,
  editFlowTemplate,
  resetToDefault,
  deleteTemplate,
  updateMessageStatus,
  getDiscountVariables,
  publishDraft,
  testDraft,
  updateDiscountEvent,
  enableDiscount,
};

export default settings_api;
